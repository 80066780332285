import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import City from '../../models/entities/city';
import stringUtils from '../../utils/strings';
import GradientOverflow from '../common/GradientOverflow';
import SimpleList, {SimpleListOption} from '../common/SimpleList';
import {pageHeight} from '../navigation/Navbar';

export type CityListProps = {
  searchText: string;
  cities: City[];
  onSelectCity: (cityId: number) => void;
  citiesNotFoundMessage?: string;
};

function CityList(props: CityListProps) {
  const {searchText, cities, onSelectCity, citiesNotFoundMessage} = props;
  const {t} = useTranslation();

  function getListOptions(): SimpleListOption[] {
    return cities
      .filter(
        (city) =>
          stringUtils.unicodeStartsWith(city.name, searchText) ||
          stringUtils.unicodeIncludes(city.name, ` ${searchText}`),
      )
      .map((city) => ({
        id: city.id.toString(),
        primary: city.name,
        secondary: `${city.bakeries?.length ?? 0} ${t('bakeries_available_bakeries_label')}`,
      }));
  }

  function handleSelectCity(cityId: string) {
    onSelectCity(Number(cityId));
  }

  return (
    <Box sx={{height: `calc(${pageHeight} - 112px)`}}>
      <GradientOverflow hideScrollbar containerProps={{sx: {padding: 2}}}>
        <SimpleList
          disableSelectedStyle
          options={getListOptions()}
          onChangeSelectedOption={handleSelectCity}
          entriesNotFoundMessage={citiesNotFoundMessage}
        />
      </GradientOverflow>
    </Box>
  );
}

export default CityList;
