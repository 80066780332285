import Country from '../models/entities/country';
import User from '../models/entities/user';

const countryUtils = {
  filterStockBakeries: function (countries: Country[], user?: User | null): Country[] {
    return countries
      .filter((country) =>
        country.districts?.some((district) =>
          district.cities?.some((city) =>
            city.bakeries?.some((bakery) => bakery.id !== user?.companyId),
          ),
        ),
      )
      .map((country) => ({
        ...country,
        districts: country.districts
          ?.filter((district) =>
            district.cities?.some((city) =>
              city.bakeries?.some((bakery) => bakery.id !== user?.companyId),
            ),
          )
          .map((district) => ({
            ...district,
            cities: district.cities
              ?.filter((city) => city.bakeries?.some((bakery) => bakery.id !== user?.companyId))
              .map((city) => ({
                ...city,
                bakeries: city.bakeries?.filter((bakery) => bakery.id !== user?.companyId),
              })),
          })),
      }));
  },
};

export default countryUtils;
