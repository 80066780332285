import {Box, Typography} from '@mui/material';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import Recipe from '../../models/entities/recipe';
import RecipeType from '../../models/entities/recipe-type';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import arrayUtils from '../../utils/arrays';
import numberUtils from '../../utils/numbers';
import ovenModelUtils from '../../utils/oven-models';
import OvenModelList from '../bakeries/OvenModelList';
import ActionBanner from '../common/ActionBanner';
import GradientOverflow from '../common/GradientOverflow';
import LoadingBackdrop from '../common/LoadingBackdrop';
import SimpleSearch from '../common/SimpleSearch';
import Tabs from '../common/Tabs';
import {pageHeight} from '../navigation/Navbar';
import RecipeTypeMenu from './RecipeTypeMenu';
import RecipesList from './RecipesList';

function Recipes() {
  const navigate = useNavigate();
  const {ovenModelId, recipeTypeId} = useParams();
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const {user, isLoading: isLoadingUser} = useAuthStore();

  const [searchText, setSearchText] = useState('');
  const [selectedRecipeId, setSelectedRecipeId] = useState<string>('');

  const {data: ovenModels = [], isLoading: isLoadingOvenModels} = useQuery({
    enabled: user != null,
    queryKey: ['companyOvenModels', user?.companyId, {expand: ['ovenGroups', 'ovens']}],
    queryFn: () =>
      services.company.getCompanyOvenModels({
        params: {companyId: user?.companyId ?? ''},
        query: {
          expand: ['ovenGroups', 'ovens'],
        },
      }),
    select: (ovenModels) => ovenModelUtils.filterStockOvens(ovenModels, user),
  });

  const selectedOvenModel = ovenModels.find(
    (ovenModel) => ovenModel.id === numberUtils.parseInt(ovenModelId),
  );

  const {data: recipeTypes = [], isLoading: isLoadingRecipeTypes} = useQuery({
    enabled: user != null && selectedOvenModel != null,
    queryKey: ['recipeTypes', {ovenModelId: selectedOvenModel?.id, companyId: user?.companyId}],
    queryFn: () =>
      services.recipeType.getRecipeTypes({
        query: {
          ovenModelId: selectedOvenModel!.id,
          companyId: user!.companyId,
        },
      }),
  });

  const selectedRecipeTypeIndex = recipeTypes.findIndex(
    (recipeType) => recipeType.id === recipeTypeId,
  );
  const selectedRecipeType =
    selectedRecipeTypeIndex > -1 ? recipeTypes[selectedRecipeTypeIndex] : undefined;

  const {data: recipes = [], isLoading: isLoadingRecipes} = useQuery({
    enabled: user != null && selectedOvenModel != null,
    queryKey: [
      'recipes',
      {ovenModelId: selectedOvenModel?.id, companyId: user?.companyId, expand: ['recipeTypes']},
    ],
    queryFn: () =>
      services.recipe.getRecipes({
        query: {
          ovenModelId: selectedOvenModel!.id,
          companyId: user!.companyId,
          expand: ['recipeTypes'],
        },
      }),
  });

  const {mutate: createRecipeType, isLoading: isLoadingCreateRecipeType} = useMutation({
    mutationFn: services.recipeType.createRecipeType,
    onSuccess: (_, {request: recipeType}) => {
      queryClient.setQueryData<RecipeType[] | undefined>(
        ['recipeTypes', {ovenModelId: selectedOvenModel?.id}],
        (recipeTypes) => (recipeTypes != null ? [...recipeTypes, recipeType] : [recipeType]),
      );
      queryClient.invalidateQueries('recipeTypes');
      navigate(`${paths.recipes}/${selectedOvenModel?.id}/${recipeType.id}`);
    },
  });

  const {mutate: updateRecipeType, isLoading: isLoadingUpdateRecipeType} = useMutation({
    mutationFn: services.recipeType.updateRecipeType,
    onSuccess: (_, {request: updatedRecipeType}) => {
      queryClient.setQueryData<RecipeType[] | undefined>(
        ['recipeTypes', {ovenModelId: selectedOvenModel?.id}],
        (recipeTypes) =>
          recipeTypes?.map((recipeType) =>
            recipeType.id === updatedRecipeType.id ? updatedRecipeType : recipeType,
          ),
      );
      queryClient.invalidateQueries('recipeTypes');
      setSelectedRecipeId('');
    },
  });

  const {mutate: deleteRecipeType, isLoading: isLoadingDeleteRecipeType} = useMutation({
    mutationFn: services.recipeType.deleteRecipeType,
    onSuccess: (recipeTypeId) => {
      queryClient.setQueryData<RecipeType[] | undefined>(
        ['recipeTypes', {ovenModelId: selectedOvenModel?.id}],
        (recipeTypes) => recipeTypes?.filter((recipeType) => recipeType.id !== recipeTypeId),
      );
      queryClient.invalidateQueries('recipeTypes');
      navigate(`${paths.recipes}/${selectedOvenModel?.id}/${recipeTypeId}`);
    },
  });

  const {mutate: deleteRecipe, isLoading: isLoadingDeleteRecipe} = useMutation({
    mutationFn: services.recipe.deleteRecipe,
    onSuccess: (recipeId) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: selectedOvenModel?.id, expand: ['recipeTypes']}],
        (recipes) => recipes?.filter((recipe) => recipe.id !== recipeId),
      );
      queryClient.invalidateQueries('recipes');
      setSelectedRecipeId('');
    },
  });

  const {mutate: deleteRecipeTypeRecipe, isLoading: isLoadingDeleteRecipeTypeRecipe} = useMutation({
    mutationFn: services.recipeType.deleteRecipeTypeRecipe,
    onSuccess: (recipeId, {params}) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: selectedOvenModel?.id, expand: ['recipeTypes']}],
        (recipes) =>
          recipes?.map((recipe) => ({
            ...recipe,
            recipeTypes:
              recipe.id === recipeId
                ? recipe.recipeTypes?.filter((recipeType) => recipeType.id !== params.recipeTypeId)
                : recipe.recipeTypes,
          })),
      );
      queryClient.invalidateQueries('recipes');
    },
  });

  useEffect(() => {
    if (selectedOvenModel == null && !arrayUtils.isNullOrEmpty(ovenModels)) {
      navigate(`${paths.recipes}/${ovenModels[0].id}/all`, {replace: true});
    }
  }, [ovenModels, selectedOvenModel, navigate]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('recipes_breadcrumb'),
      onClick: () => navigate(paths.recipes),
    });
    if (selectedOvenModel != null) {
      breadcrumbs.push({
        title: selectedOvenModel.description,
        onClick: () => navigate(`${paths.recipes}/${selectedOvenModel.id}/all`),
      });

      if (selectedRecipeType != null) {
        breadcrumbs.push({title: selectedRecipeType.description});
      }
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOvenModel, selectedRecipeType]);

  function getRecipeTypeTabs() {
    const tabs = recipeTypes.map((recipeType) => recipeType.description);
    tabs.unshift(t('recipe_type_all_label'));
    return tabs;
  }

  function handleChangeSelectedTab(tabIndex: number) {
    setSearchText('');
    setSelectedRecipeId('');
    const recipeTypeId = tabIndex === 0 ? 'all' : recipeTypes[tabIndex - 1].id;
    navigate(`${paths.recipes}/${selectedOvenModel?.id}/${recipeTypeId}`);
  }

  const isLoading =
    isLoadingUser ||
    isLoadingOvenModels ||
    isLoadingRecipeTypes ||
    isLoadingRecipes ||
    isLoadingCreateRecipeType ||
    isLoadingUpdateRecipeType ||
    isLoadingDeleteRecipeType ||
    isLoadingDeleteRecipe ||
    isLoadingDeleteRecipeTypeRecipe;

  const renderRecipeTypeMenu = selectedOvenModel != null && !isLoadingRecipeTypes;
  const renderRecipeList = selectedOvenModel != null && !isLoadingRecipes;

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
        }}>
        <Box
          sx={{
            width: '140px',
            height: `calc(${pageHeight} - 96px)`,
            marginTop: '64px',
          }}>
          <GradientOverflow>
            <OvenModelList
              ovenModels={ovenModels}
              selectedOvenModelId={selectedOvenModel?.id}
              onSelectOvenModel={(ovenModelId) => navigate(`${paths.recipes}/${ovenModelId}/all`)}
            />
          </GradientOverflow>
        </Box>
      </Box>
      <Box sx={{width: '80vw'}}>
        <Box sx={{display: 'flex'}}>
          <Box sx={{width: '65vw'}}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '112px',
              }}>
              <Tabs
                values={getRecipeTypeTabs()}
                selectedTabIndex={selectedRecipeTypeIndex >= 0 ? selectedRecipeTypeIndex + 1 : 0}
                onTabClick={handleChangeSelectedTab}
              />
              <SimpleSearch searchText={searchText} onSearchText={setSearchText} />
            </Box>
            <Box sx={{marginBlock: 2}}>
              {!arrayUtils.isNullOrEmpty(ovenModels) && (
                <ActionBanner
                  text={t('recipe_create_recipe_label')}
                  onClick={() =>
                    navigate(
                      `${paths.recipes}/${selectedOvenModel?.id}/${
                        selectedRecipeType?.id ?? 'all'
                      }/create`,
                    )
                  }
                />
              )}
            </Box>
          </Box>
          <Box sx={{width: '15vw'}}>
            {renderRecipeTypeMenu && (
              <RecipeTypeMenu
                selectedOvenModel={selectedOvenModel}
                selectedRecipeType={selectedRecipeType}
                onCreateRecipeType={(recipeType) =>
                  createRecipeType({
                    request: recipeType,
                  })
                }
                onUpdateRecipeType={(recipeType) =>
                  updateRecipeType({
                    params: {
                      recipeTypeId: recipeType.id,
                    },
                    request: recipeType,
                  })
                }
                onDeleteRecipeType={(recipeTypeId) =>
                  deleteRecipeType({
                    params: {
                      recipeTypeId,
                    },
                  })
                }
              />
            )}
          </Box>
        </Box>
        <Box sx={{height: `calc(${pageHeight} - 196px)`}}>
          {arrayUtils.isNullOrEmpty(ovenModels) && !isLoadingOvenModels && (
            <Box sx={{width: '65vw', padding: 2}}>
              <Typography variant="body2" color="secondary">
                {t('recipes_oven_models_not_found_label')}
              </Typography>
            </Box>
          )}
          {renderRecipeList && (
            <RecipesList
              searchText={searchText}
              selectedOvenModelId={selectedOvenModel?.id}
              selectedRecipeTypeId={selectedRecipeType?.id ?? 'all'}
              recipes={recipes}
              selectedRecipeId={selectedRecipeId}
              setSelectedRecipeId={setSelectedRecipeId}
              onDeleteRecipe={(recipeId) =>
                deleteRecipe({
                  params: {
                    recipeId,
                  },
                })
              }
              onDeleteRecipeTypeRecipe={(recipeTypeId, recipeId) =>
                deleteRecipeTypeRecipe({
                  params: {
                    recipeTypeId,
                    recipeId,
                  },
                })
              }
            />
          )}
        </Box>
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default Recipes;
