import {Box, Typography} from '@mui/material';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import Oven from '../../models/entities/oven';
import OvenChamber from '../../models/entities/oven-chamber';
import OvenPanel from '../../models/entities/oven-panel';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import arrayUtils from '../../utils/arrays';
import countryUtils from '../../utils/countries';
import numberUtils from '../../utils/numbers';
import OvenModelList from '../bakeries/OvenModelList';
import GradientOverflow from '../common/GradientOverflow';
import LoadingBackdrop from '../common/LoadingBackdrop';
import {pageHeight} from '../navigation/Navbar';
import BakeryList from './BakeryList';
import CityList from './CityList';
import CountryTabs from './CountryTabs';
import OvenStatistics from './OvenStatistics';
import OvenTable from './OvenTable';
import SearchField from './SearchField';

function Statistics() {
  const navigate = useNavigate();
  const {countryId, cityId, bakeryId, ovenModelId} = useParams();
  const [searchParams] = useSearchParams();
  const {t} = useTranslation();

  const {user, isLoading: isLoadingUser} = useAuthStore();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [searchText, setSearchText] = useState('');
  const [ovenGroupId, setOvenGroupId] = useState(searchParams.get('ovenGroupId'));
  const [ovenId, setOvenId] = useState(searchParams.get('ovenId'));
  const [ovenChamberId, setOvenChamberId] = useState(searchParams.get('ovenChamberId'));
  const [ovenPanelId, setOvenPanelId] = useState(searchParams.get('ovenPanelId'));

  const {data: countries = [], isLoading: isLoadingCountries} = useQuery({
    enabled: user != null,
    queryKey: ['companyCountries', user?.companyId],
    queryFn: () =>
      services.company.getCompanyCountries({
        params: {
          companyId: user!.companyId,
        },
        query: {
          expand: ['districts', 'cities', 'bakeries'],
        },
      }),
    select: (countries) => countryUtils.filterStockBakeries(countries, user),
  });

  const {selectedCountry, cities, selectedCity, bakeries, selectedBakery} = useMemo(() => {
    const selectedCountryId = numberUtils.parseInt(countryId);
    const selectedCountry = countries.find((country) => country.id === selectedCountryId);
    const cities = selectedCountry?.districts?.flatMap((district) => district.cities ?? []) ?? [];
    const selectedCityId = numberUtils.parseInt(cityId);
    const selectedCity = cities.find((city) => city.id === selectedCityId);
    const bakeries = selectedCity?.bakeries ?? [];
    const selectedBakery = bakeries.find((bakery) => bakery.id === bakeryId);
    return {selectedCountry, cities, selectedCity, bakeries, selectedBakery};
  }, [user, countries, countryId, cityId, bakeryId]);

  const {data: ovenModels = [], isLoading: isLoadingOvenModels} = useQuery({
    enabled: user != null && selectedBakery != null,
    queryKey: ['bakeryOvenModels', selectedBakery?.id],
    queryFn: () =>
      services.bakery.getBakeryOvenModels({
        params: {
          bakeryId: selectedBakery!.id,
        },
        query: {
          expand: ['ovenGroups', 'ovens', 'ovenChambers', 'ovenPanels'],
        },
      }),
  });

  const {
    selectedOvenModel,
    selectedOvenGroup,
    selectedOven,
    selectedOvenChamber,
    selectedOvenPanel,
  } = useMemo(() => {
    const selectedOvenModel = ovenModels.find(
      (ovenModel) => ovenModel.id === numberUtils.parseInt(ovenModelId, 1),
    );
    const selectedOvenGroup = selectedOvenModel?.ovenGroups?.find(
      (ovenGroup) => ovenGroup.id === ovenGroupId,
    );
    const selectedOven = selectedOvenModel?.ovens?.find((oven) => oven.id === ovenId);
    const selectedOvenChamber = selectedOven?.ovenChambers?.find(
      (ovenChamber) => ovenChamber.id === ovenChamberId,
    );
    const selectedOvenPanel = (selectedOvenChamber?.ovenPanels ?? selectedOven?.ovenPanels)?.find(
      (ovenPanel) => ovenPanel.id === ovenPanelId,
    );
    return {
      selectedOvenModel,
      selectedOvenGroup,
      selectedOven,
      selectedOvenChamber,
      selectedOvenPanel,
    };
  }, [ovenModels, ovenModelId, ovenGroupId, ovenId, ovenChamberId, ovenPanelId]);

  useEffect(() => {
    if (selectedCountry == null && !arrayUtils.isNullOrEmpty(countries)) {
      navigate(`${paths.statistics}/${countries[0].id}`, {replace: true});
    }
  }, [countries, selectedCountry, navigate]);

  useEffect(() => {
    if (
      selectedCountry != null &&
      selectedCity != null &&
      selectedBakery != null &&
      selectedOvenModel == null &&
      !arrayUtils.isNullOrEmpty(ovenModels)
    ) {
      navigate(
        `${paths.statistics}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}/${ovenModels[0].id}`,
        {replace: true},
      );
    }
  }, [ovenModels, selectedCountry, selectedCity, selectedBakery, selectedOvenModel, navigate]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('statistics_breadcrumb'),
      onClick: () => navigate(paths.statistics),
    });
    if (selectedCountry != null) {
      breadcrumbs.push({
        title: selectedCountry.name,
        onClick: () => navigate(`${paths.statistics}/${selectedCountry.id}`),
      });

      if (selectedCity != null) {
        breadcrumbs.push({
          title: selectedCity.name,
          onClick: () => navigate(`${paths.statistics}/${selectedCountry.id}/${selectedCity.id}`),
        });

        if (selectedBakery != null) {
          breadcrumbs.push({
            title: selectedBakery.name,
            onClick: () =>
              navigate(
                `${paths.statistics}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}`,
              ),
          });

          if (selectedOvenModel != null) {
            breadcrumbs.push({
              title: selectedOvenModel.description,
              onClick: () => {
                setOvenGroupId(null);
                setOvenId(null);
                setOvenChamberId(null);
                setOvenPanelId(null);
              },
            });

            if (selectedOvenGroup != null) {
              breadcrumbs.push({
                title: selectedOvenGroup.description ?? '',
              });
            } else if (selectedOven != null) {
              breadcrumbs.push({
                title: selectedOven.description ?? '',
              });
            }
          }
        }
      }
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCountry,
    selectedCity,
    selectedBakery,
    selectedOvenModel,
    selectedOvenGroup,
    selectedOven,
  ]);

  function handleSelectOvenModel(ovenModelId: number) {
    setOvenGroupId(null);
    setOvenId(null);
    setOvenChamberId(null);
    setOvenPanelId(null);
    navigate(
      `${paths.statistics}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${ovenModelId}`,
    );
  }

  function handleSelectOvenGroup(ovenGroupId: string) {
    const ovenGroup = selectedOvenModel?.ovenGroups?.find(
      (ovenGroup) => ovenGroup.id === ovenGroupId,
    );
    const oven = ovenGroup?.ovens?.reduce(
      (previous, current) =>
        (previous?.ovenGroupOrder ?? 1) > (current.ovenGroupOrder ?? 1) ? previous : current,
      undefined as Oven | undefined,
    );
    const ovenPanel = oven?.ovenPanels?.[0];
    setOvenGroupId(ovenGroup?.id ?? null);
    setOvenId(oven?.id ?? null);
    setOvenChamberId(null);
    setOvenPanelId(ovenPanel?.id ?? null);
  }

  function handleSelectOven(ovenId: string) {
    const oven = selectedOvenModel?.ovens?.find((oven) => oven.id === ovenId);
    const ovenChamber = oven?.ovenChambers?.reduce(
      (previous, current) =>
        (previous?.ovenOrder ?? 1) > (current.ovenOrder ?? 1) ? previous : current,
      undefined as OvenChamber | undefined,
    );
    const ovenPanel =
      ovenChamber != null
        ? ovenChamber.ovenPanels?.[0]
        : oven?.ovenPanels?.reduce(
            (previous, current) =>
              (previous?.ovenOrder ?? 1) > (current.ovenOrder ?? 1) ? previous : current,
            undefined as OvenPanel | undefined,
          );
    setOvenId(oven?.id ?? null);
    setOvenChamberId(ovenChamber?.id ?? null);
    setOvenPanelId(ovenPanel?.id ?? null);
  }

  function handleSelectOvenPanel(ovenId: string, ovenPanelId: string, ovenChamberId?: string) {
    setOvenId(ovenId);
    setOvenChamberId(ovenChamberId ?? null);
    setOvenPanelId(ovenPanelId);
  }

  const isLoading = isLoadingUser || isLoadingCountries || isLoadingOvenModels;

  const renderCityList = selectedCity == null;
  const renderBakeryList = selectedCity != null && selectedBakery == null;
  const renderOvenModelList = selectedBakery != null;
  const renderOvenTable =
    selectedBakery != null &&
    selectedOvenGroup == null &&
    selectedOven == null &&
    !isLoadingOvenModels;
  const renderOvenStatistics = selectedOvenGroup != null || selectedOven != null;
  const ovensNotFound =
    (selectedOvenModel?.ovenGroups?.length ?? 0) === 0 &&
    (selectedOvenModel?.ovens?.length ?? 0) === 0;

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
        }}>
        <Box
          sx={{
            width: '140px',
            height: `calc(${pageHeight} - 96px)`,
            marginTop: '64px',
          }}>
          {renderOvenModelList && (
            <GradientOverflow>
              <OvenModelList
                ovenModels={ovenModels}
                selectedOvenModelId={selectedOvenModel?.id}
                onSelectOvenModel={handleSelectOvenModel}
              />
            </GradientOverflow>
          )}
        </Box>
      </Box>
      <Box sx={{width: '65vw'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '112px',
          }}>
          <CountryTabs
            countries={countries}
            selectedCountryId={selectedCountry?.id}
            onSelectCountry={(countryId) => navigate(`${paths.statistics}/${countryId}`)}
          />
          <SearchField
            searchText={searchText}
            setSearchText={setSearchText}
            selectedCity={selectedCity}
            selectedBakery={selectedBakery}
            selectedOvenGroup={selectedOvenGroup}
            selectedOven={selectedOven}
            selectedOvenChamber={selectedOvenChamber}
            selectedOvenPanel={selectedOvenPanel}
            onReturnToBakeries={() =>
              navigate(`${paths.statistics}/${selectedCountry?.id}/${selectedCity?.id}`)
            }
            onReturnToBakery={() =>
              navigate(
                `${paths.statistics}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}`,
              )
            }
            onReturnToOvenModel={() => handleSelectOvenModel(selectedOvenModel?.id ?? 1)}
          />
        </Box>
        <Box
          sx={{
            height: `calc(${pageHeight} - 112px)`,
          }}>
          {renderCityList && (
            <CityList
              searchText={searchText}
              cities={cities}
              onSelectCity={(cityId) =>
                navigate(`${paths.statistics}/${selectedCountry?.id}/${cityId}`)
              }
              citiesNotFoundMessage={
                selectedCountry == null ? '' : t('statistics_cities_not_found_label')
              }
            />
          )}
          {renderBakeryList && (
            <BakeryList
              searchText={searchText}
              bakeries={bakeries}
              onSelectBakery={(bakeryId) =>
                navigate(
                  `${paths.statistics}/${selectedCountry?.id}/${selectedCity?.id}/${bakeryId}`,
                )
              }
              bakeriesNotFoundMessage={
                selectedCity == null ? '' : t('statistics_bakeries_not_found_label')
              }
            />
          )}
          {renderOvenTable && ovensNotFound && (
            <Typography variant="body2" sx={{color: 'text.primary', padding: 2}}>
              {t('statistics_ovens_not_found_label')}
            </Typography>
          )}
          {renderOvenTable && !ovensNotFound && (
            <OvenTable
              ovenGroups={selectedOvenModel?.ovenGroups ?? []}
              ovens={selectedOvenModel?.ovens?.filter((oven) => oven.ovenGroupId == null) ?? []}
              onSelectOvenGroup={handleSelectOvenGroup}
              onSelectOven={handleSelectOven}
            />
          )}
          {renderOvenStatistics && (
            <OvenStatistics
              selectedOvenGroup={selectedOvenGroup}
              selectedOven={selectedOven}
              selectedOvenChamberId={ovenChamberId}
              selectedOvenPanelId={ovenPanelId}
              onSelectOvenPanel={handleSelectOvenPanel}
            />
          )}
        </Box>
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default Statistics;
