import {Box, Typography} from '@mui/material';
import {TFunction} from 'i18next';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import OvenChamber from '../../../models/entities/oven-chamber';
import {OvenModelId} from '../../../models/entities/oven-model';
import {OvenSubModelId} from '../../../models/entities/oven-sub-model';
import paths from '../../../routes/paths';
import services from '../../../services/provider';
import useAuthStore from '../../../state/auth';
import useBreadcrumbsStore, {Breadcrumb} from '../../../state/breadcrumbs';
import countryUtils from '../../../utils/countries';
import cryptoUtils from '../../../utils/crypto';
import numberUtils from '../../../utils/numbers';
import ovenModelUtils from '../../../utils/oven-models';
import LoadingBackdrop from '../../common/LoadingBackdrop';
import SearchField from '../SearchField';
import AddOvenMenu from './AddOvenMenu';
import ModelStep from './steps/ModelStep';
import OptionStep from './steps/OptionStep';
import OvensStep from './steps/OvensStep';
import SubModelStep from './steps/SubModelStep';
import SerialNumberStep from './steps/serial-number/SerialNumberStep';

export type AddOvenSteps = {
  model: {visible: boolean; error: boolean};
  subModel: {visible: boolean; error: boolean};
  option: {visible: boolean; error: boolean};
  ovens: {visible: boolean; error: boolean};
  chambersNumber: {visible: boolean; error: boolean};
  serialNumber: {visible: boolean; error: boolean};
  description: {visible: boolean; error: boolean};
};

export type AddOvenStep = keyof AddOvenSteps;

export type AddOvenData = {
  ovenModelId: number | null;
  ovenSubModelId: number | null;
  selectedOvenId: string | null;
  serialNumber: string;
  topOvenSerialNumber: string;
  bottomOvenSerialNumber: string;
  ovenChamberSerialNumbers: string[];
  description: string;
};

function getStepTitle(step: AddOvenStep, addOvenData: AddOvenData, t: TFunction) {
  switch (step) {
    case 'model':
      return t('add_oven_select_model_label');
    case 'subModel':
      return t('add_oven_select_sub_model_label');
    case 'option':
      return t('add_oven_select_option_label');
    case 'ovens':
      return t('add_oven_select_existing_oven_label');
    case 'chambersNumber':
      return t('add_oven_enter_total_oven_chambers_label');
    case 'serialNumber':
      if (addOvenData.ovenModelId === OvenModelId.Modulram) {
        if (addOvenData.selectedOvenId != null) {
          return t('add_oven_enter_new_oven_chamber_serial_number_label');
        }
        return t('add_oven_enter_oven_chamber_serial_numbers_label');
      }
      if (
        addOvenData.ovenModelId === OvenModelId.Turboram &&
        (addOvenData.ovenSubModelId === OvenSubModelId.TurboramCombi ||
          addOvenData.ovenSubModelId === OvenSubModelId.TurboramHomeModule)
      ) {
        if (addOvenData.selectedOvenId != null) {
          return t('add_oven_enter_new_oven_serial_number_label');
        }
        return t('add_oven_enter_oven_serial_numbers_label');
      }
      return t('add_oven_enter_oven_serial_number_label');
    case 'description':
      return t('add_oven_enter_oven_description_label');
  }
}

function AddOven() {
  const navigate = useNavigate();
  const {countryId, cityId, bakeryId} = useParams();
  const {t} = useTranslation();

  const user = useAuthStore((state) => state.user);
  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [steps, setSteps] = useState<AddOvenSteps>({
    model: {visible: true, error: true},
    subModel: {visible: false, error: false},
    option: {visible: false, error: false},
    ovens: {visible: false, error: false},
    chambersNumber: {visible: false, error: false},
    serialNumber: {visible: false, error: false},
    description: {visible: false, error: false},
  });
  const [selectedStep, setSelectedStep] = useState<AddOvenStep>('model');
  const [addOvenData, setAddOvenData] = useState<AddOvenData>({
    ovenModelId: null,
    ovenSubModelId: null,
    selectedOvenId: null,
    serialNumber: '',
    topOvenSerialNumber: '',
    bottomOvenSerialNumber: '',
    ovenChamberSerialNumbers: [],
    description: '',
  });

  const {data: countries = [], isLoading: isLoadingCountries} = useQuery({
    enabled: user != null,
    queryKey: ['companyCountries', user?.companyId],
    queryFn: () =>
      services.company.getCompanyCountries({
        params: {
          companyId: user!.companyId,
        },
        query: {
          expand: ['districts', 'cities', 'bakeries'],
        },
      }),
    select: (countries) => countryUtils.filterStockBakeries(countries, user),
  });

  const {selectedCountry, selectedCity, selectedBakery} = useMemo(() => {
    const selectedCountryId = numberUtils.parseInt(countryId);
    const selectedCountry = countries.find((country) => country.id === selectedCountryId);
    const cities = selectedCountry?.districts?.flatMap((district) => district.cities ?? []) ?? [];
    const selectedCityId = numberUtils.parseInt(cityId);
    const selectedCity = cities.find((city) => city.id === selectedCityId);
    const bakeries = selectedCity?.bakeries ?? [];
    const selectedBakery = bakeries.find((bakery) => bakery.id === bakeryId);
    return {selectedCountry, cities, selectedCity, bakeries, selectedBakery};
  }, [countries, countryId, cityId, bakeryId]);

  const {data: ovens = [], isLoading: isLoadingOvens} = useQuery({
    enabled: user != null,
    queryKey: [
      'ovens',
      {bakeryIds: [selectedBakery?.id, user?.companyId], expand: ['ovenChambers', 'ovenPanels']},
    ],
    queryFn: () =>
      services.oven.getOvens({
        query: {
          bakeryIds: [selectedBakery?.id, user?.companyId].filter(Boolean) as string[],
          expand: ['ovenChambers', 'ovenPanels'],
        },
      }),
  });

  const {data: ovenChambers = [], isLoading: isLoadingOvenChambers} = useQuery({
    enabled: user != null,
    queryKey: ['ovenChambers', {bakeryIds: [selectedBakery?.id, user?.companyId]}],
    queryFn: () =>
      services.ovenChamber.getOvenChambers({
        query: {
          bakeryIds: [selectedBakery?.id, user?.companyId].filter(Boolean) as string[],
        },
      }),
  });

  const {mutate: updateOven, isLoading: isLoadingUpdateOven} = useMutation({
    mutationFn: services.oven.updateOven,
    onSuccess: () =>
      navigate(
        `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/ovens/${addOvenData.ovenModelId}`,
      ),
  });

  const {mutate: createOvenGroup, isLoading: isLoadingCreateOvenGroup} = useMutation({
    mutationFn: services.ovenGroup.createOvenGroup,
    onSuccess: () =>
      navigate(
        `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/ovens/${addOvenData.ovenModelId}`,
      ),
  });

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('bakeries_breadcrumb'),
      onClick: () => navigate(paths.bakeries),
    });
    if (selectedCountry != null) {
      breadcrumbs.push({
        title: selectedCountry.name,
        onClick: () => navigate(`${paths.bakeries}/${selectedCountry.id}`),
      });

      if (selectedCity != null) {
        breadcrumbs.push({
          title: selectedCity.name,
          onClick: () => navigate(`${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}`),
        });

        if (selectedBakery != null) {
          breadcrumbs.push({
            title: selectedBakery.name,
            onClick: () =>
              navigate(
                `${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}`,
              ),
          });

          breadcrumbs.push({
            title: t('bakery_settings_breadcrumb'),
            onClick: () =>
              navigate(
                `${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}/settings`,
              ),
          });

          breadcrumbs.push({
            title: t('add_oven_breadcrumb'),
            onClick: () => {
              setSteps({
                model: {visible: true, error: true},
                subModel: {visible: false, error: false},
                option: {visible: false, error: false},
                ovens: {visible: false, error: false},
                chambersNumber: {visible: false, error: false},
                serialNumber: {visible: false, error: false},
                description: {visible: false, error: false},
              });
              setSelectedStep('model');
              setAddOvenData({
                ovenModelId: null,
                ovenSubModelId: null,
                selectedOvenId: null,
                serialNumber: '',
                topOvenSerialNumber: '',
                bottomOvenSerialNumber: '',
                ovenChamberSerialNumbers: [],
                description: '',
              });
            },
          });

          if (addOvenData.ovenModelId != null) {
            breadcrumbs.push({
              title: ovenModelUtils.getDescription(addOvenData.ovenModelId),
            });
          }
        }
      }
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, selectedCity, selectedBakery, addOvenData]);

  function handleAddOven() {
    if (user == null || selectedBakery == null || addOvenData.ovenModelId == null) {
      return;
    }

    if (
      addOvenData.ovenModelId === OvenModelId.Turboram &&
      (addOvenData.ovenSubModelId === OvenSubModelId.TurboramCombi ||
        addOvenData.ovenSubModelId === OvenSubModelId.TurboramHomeModule)
    ) {
      const topOven = ovens.find((oven) => oven.serialNumber === addOvenData.topOvenSerialNumber);
      const bottomOven = ovens.find(
        (oven) => oven.serialNumber === addOvenData.bottomOvenSerialNumber,
      );

      if (topOven != null && bottomOven != null) {
        const ovenGroupId = cryptoUtils.uuid();
        topOven.ovenGroupId = ovenGroupId;
        topOven.ovenGroupOrder = 2;
        topOven.bakeryId = selectedBakery.id;
        bottomOven.ovenGroupId = ovenGroupId;
        bottomOven.ovenGroupOrder = 1;
        bottomOven.bakeryId = selectedBakery.id;
        createOvenGroup({
          request: {
            id: cryptoUtils.uuid(),
            description: addOvenData.description,
            ovenModelId: OvenModelId.Turboram,
            ovenSubModelId: null,
            bakeryId: selectedBakery.id,
            companyId: user.companyId,
            ovens: [bottomOven, topOven],
          },
        });
      }

      return;
    }

    // TODO: review implementation (this implementation assumes that one of the oven chambers has an oven)
    if (addOvenData.ovenModelId === OvenModelId.Modulram) {
      const targetOvenChambers = [...addOvenData.ovenChamberSerialNumbers]
        .reverse()
        .map((serialNumber) =>
          ovenChambers.find((ovenChamber) => ovenChamber.serialNumber === serialNumber),
        )
        .filter(Boolean) as OvenChamber[];

      const ovenId = targetOvenChambers.find((ovenChamber) => ovenChamber.ovenId != null)?.ovenId;
      const oven = ovens.find((oven) => oven.id === ovenId);

      if (oven != null) {
        targetOvenChambers.forEach((ovenChamber, index) => {
          ovenChamber.ovenId = oven.id;
          ovenChamber.ovenOrder = index + 1;
          ovenChamber.bakeryId = selectedBakery.id;
        });

        oven.description = addOvenData.description;
        oven.bakeryId = selectedBakery.id;
        oven.ovenChambers = targetOvenChambers;

        updateOven({
          params: {ovenId: oven.id},
          request: oven,
        });
      }

      return;
    }

    const oven = ovens.find((oven) => oven.serialNumber === addOvenData.serialNumber);

    if (oven != null) {
      oven.description = addOvenData.description;
      oven.bakeryId = selectedBakery.id;
      updateOven({
        params: {ovenId: oven.id},
        request: oven,
      });
    }
  }

  const isLoading =
    isLoadingCountries ||
    isLoadingOvens ||
    isLoadingOvenChambers ||
    isLoadingUpdateOven ||
    isLoadingCreateOvenGroup;

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '20vw',
          marginBlock: '112px',
        }}>
        <AddOvenMenu
          steps={steps}
          setSteps={setSteps}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          onAddOven={handleAddOven}
        />
      </Box>
      <Box sx={{width: '80vw'}}>
        <Box sx={{width: '65vw'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '112px',
            }}>
            <SearchField
              searchText=""
              setSearchText={() => {}}
              selectedCity={selectedCity}
              selectedBakery={selectedBakery}
              onReturnToBakeries={() =>
                navigate(`${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}`)
              }
              hideSearchIcon
            />
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                color: 'text.primary',
                marginBlock: 2,
                paddingInline: 2,
              }}>
              {getStepTitle(selectedStep, addOvenData, t)}
            </Typography>
          </Box>
          {selectedStep === 'model' && (
            <ModelStep
              ovens={ovens}
              setSteps={setSteps}
              setSelectedStep={setSelectedStep}
              addOvenData={addOvenData}
              setAddOvenData={setAddOvenData}
            />
          )}
          {selectedStep === 'subModel' && selectedBakery != null && (
            <SubModelStep
              ovens={ovens}
              setSteps={setSteps}
              setSelectedStep={setSelectedStep}
              setAddOvenData={setAddOvenData}
            />
          )}
          {selectedStep === 'option' && (
            <OptionStep
              setSteps={setSteps}
              setSelectedStep={setSelectedStep}
              setAddOvenData={setAddOvenData}
            />
          )}
          {selectedStep === 'ovens' && selectedBakery != null && (
            <OvensStep
              ovens={ovens}
              setSteps={setSteps}
              addOvenData={addOvenData}
              setAddOvenData={setAddOvenData}
            />
          )}
          {['chambersNumber', 'serialNumber', 'description'].includes(selectedStep) && (
            <SerialNumberStep
              ovens={ovens}
              ovenChambers={ovenChambers}
              steps={steps}
              setSteps={setSteps}
              selectedStep={selectedStep}
              setSelectedStep={setSelectedStep}
              addOvenData={addOvenData}
              setAddOvenData={setAddOvenData}
            />
          )}
        </Box>
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default AddOven;
